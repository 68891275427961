import logo from './homelab.webp';
import './App.css';

function App() {
  console.log()
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Homelab - Wee!
        </h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          There's nothing here for you.
        </p>
      </header>
    </div>
  );
}

export default App;
